import React, { useEffect } from 'react';
import { __ } from 'utils/translationOld';
import history from 'utils/history';

const Admin = () => {
  const linkUrl = __("text0001");

  useEffect(() => {
    window.open(linkUrl, "_self");
  }, []); 


  return (
    <div/>
  );
};
  
export default Admin; 
