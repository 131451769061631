import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { __ } from 'utils/translation';
import logo192 from 'images/logo192.png';
import unnamed from 'images/unnamed.jpg';
import Avatar from './Avatar';
import Lang from 'components/Lang';
import { getSearchParams } from 'utils/url';
import './index.scss';



const Avatars = ({ data }) => {
    var params = getSearchParams(window.location.search);
    const { lang } = useSelector((state) => state.config.config);
    const [orderProps, setOrderProps] = useState({});
    const [visualProps, setVisualProps] = useState([]); 

    useEffect(() => {
        const isPwa = params['pwa'] === "" || params['pwa'];

        const ignoredParams = ["pwa", "demo"];
        const props = {}
        const vprops = [];
        props[__(isPwa ? "Отправлено из установленного приложения" : "Отправлено с сайта", lang ?? "en")] = "";
        for (var propName in params) {
            if (propName && !ignoredParams.includes(propName)) {
                props[propName] = params[propName];
                vprops.push({ key: propName, value: params[propName] });
            }
        }
        setOrderProps(props);
        setVisualProps(vprops);
    }, []);

    let isRestauranteEs = false;
    if (params['mesa'] === "" || params['mesa']) {
        isRestauranteEs = true;
    };
    let isRestauranteEn = false;
    if (params['table'] === "" || params['table']) {
        isRestauranteEn = true;
    };
    let isRestauranteRu = false;
    if (params['стол'] === "" || params['стол']) {
        isRestauranteRu = true;
    };
    let isRestauranteIt = false;
    if (params['tavolo'] === "" || params['tavolo']) {
        isRestauranteIt = true;
    };
    let isRestauranteFr = false;
    if (params['table'] === "" || params['table']) {
        isRestauranteFr = true;
    };
    let isRestauranteDe = false;
    if (params['tabelle'] === "" || params['tabelle']) {
        isRestauranteDe = true;
    };
    let isRestauranteZh = false;
    if (params['表'] === "" || params['表']) {
        isRestauranteZh = true;
    };
    let isWaiter = false;
    if (params['waiter'] === "" || params['waiter']) {
        isWaiter = true;
    };
    let isSelfService = false;
    if (params['selfservice'] === "" || params['selfservice']) {
        isSelfService = true;
    };
    let isWaiterSelfService = false;
    if (params['waiterselfservice'] === "" || params['waiterselfservice']) {
        isWaiterSelfService = true;
    };
    let isDelivery = false;
    if (params['delivery'] === "" || params['delivery']) {
        isDelivery = true;
    };
    let isTakeAway = false;
    if (params['takeaway'] === "" || params['takeaway']) {
        isTakeAway = true;
    };
    let isIAmWaiter = false;
    if (params['iamwaiter'] === "" || params['iamwaiter']) {
        isIAmWaiter = true;
    };
    let isTable = false;
    if (isRestauranteEs || isRestauranteEn || isRestauranteRu || isRestauranteIt || isRestauranteFr || isRestauranteDe || isRestauranteZh) {
        isTable = true
    };
    let isNotSendOrderNotCallWaiter = false;
    if (isTable && !isWaiter && !isSelfService) {
        isNotSendOrderNotCallWaiter = true
    };
    let isCallWaiterNotSendOrder = false;
    if (isTable && isWaiter && !isSelfService) {
        isCallWaiterNotSendOrder = true
    };
    let isSendOrderNotCallWaiter = false;
    if (isTable && isSelfService) {
        isSendOrderNotCallWaiter = true
    };
    let isSendOrderAndCallWaiter = false;
    if (isTable && isWaiterSelfService) {
        isSendOrderAndCallWaiter = true
    };

    let tabelNumber = "";
    let nameWaiter = "";
    if (isIAmWaiter) {
        tabelNumber = (visualProps.slice(0, 1).map(e => e.value).toString().replace(",", ""));
        nameWaiter = (visualProps.slice(1, 2).map(e => e.value).toString().replace(",", ""));
    }

    var referrer = document.referrer;  
    console.log("document.referrer", document.referrer);

    return (
        <React.Fragment>
            <div className="avatar-maps-username-box">
                {!isIAmWaiter &&
                    <>
                        {data.length < 1 && (
                            <>
                                <h1 className="main-page__page1__h3"
                                    onClick={() => { }}>
                                    FOR GOOGLE MAPS MENU
                                </h1>
                            </>
                        )}

                        {
                            data.map((avatar) => <Avatar key={avatar.guid} {...avatar} />)
                        }
                        <div className="avatars-lang-box">
                            <Lang />
                        </div>    
                    </>
                    }

                    {isIAmWaiter &&
                    <>
                    <div className="avatar-maps" onClick={() => window.open(referrer, "_self")} style={{ backgroundImage: `URL(${unnamed})` }} />
                                <h1 className="main-page__page1__h3"
                                    onClick={() => window.open(referrer, "_self")} >
                                    {__("Стол")}:&nbsp;{tabelNumber},&nbsp;({nameWaiter})
                                </h1>
                                </>
                                }

            </div>


        </React.Fragment >
    );
};

Avatars.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({}))
};

Avatars.defaultProps = {
    data: []
};

export default Avatars;