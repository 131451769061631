import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { CookiesProvider } from 'react-cookie';
import ReactPWAInstallProvider from "components/PwaInstall/component";
import Admin from 'components/Admin';
import { getSearchParams } from 'utils/url';
import { __ } from 'utils/translation';

import history from 'utils/history';
import store from 'store';
import storePwa from 'storePwa';

import App from "components/App";

import * as serviceWorker from './serviceWorker';

import './index.scss';

const url = window.location.href.split('?')[0];
const path =  new URL(url);

const language = (window.navigator.userLanguage || window.navigator.language)?.substring(0, 2)?.toLowerCase();


if (path.pathname.length > 11) {
ReactDOM.render(
    (
      <CookiesProvider>     
            {getSearchParams(window.location.search)['pwa'] === "app" ?
            <ReactPWAInstallProvider enableLogging>
                <Router history={history}> 
                  <Provider store={storePwa}>
                    <App />
                  </Provider>
                </Router>
              </ReactPWAInstallProvider>
            :
              <Router history={history}>
                <Provider store={store}>
                  <App />
                </Provider>
              </Router>
            }          
      </CookiesProvider>
    ),
    document.getElementById('root'),   
     
  );
serviceWorker.register();
} else {  
  
      ReactDOM.render(
        (
          <Admin />          
        ),
        document.getElementById('root')
        
      );
    serviceWorker.unregister();
        
}

