import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { __ } from 'utils/translation';
import Button from 'components/common/Button';
import { getSearchParams } from 'utils/url';
import Icon from 'components/common/Icon';

import './index.scss';

import {
  CATALOG_TABLE_LEFT
} from 'constants/catalogTableTypes';

import { translatedProperty } from 'utils/translation';


const CatalogTable = ({
  dataOrders,
  guid,
  animation,
  image,
  price,
  currency,
  number,
  type,
  text,
  textEn,
  textRu,
  textEs,
  textIt,
  textDe,
  textFr,
  textZh,
  textAlt,
  textAltEn,
  textAltRu,
  textAltEs,
  textAltIt,
  textAltDe,
  textAltFr,
  textAltZh,
  onClick,
  className,
  technical,
  reviews,
  unavailableForBooking,
  unavailableSelfService,
  unavailableCallingWaiter


}) => {
  const [orderProps, setOrderProps] = useState({});
  const [visualProps, setVisualProps] = useState([]);
  const { lang } = useSelector((state) => state.config.config);
  var params = getSearchParams(window.location.search);
  const { url } = useSelector((state) => state.config.data);

  useEffect(() => {
    const isPwa = params['pwa'] === "" || params['pwa'];

    const ignoredParams = ["pwa", "demo",];
    const props = {}
    const vprops = [];
    props[__(isPwa ? "Отправлено из установленного приложения" : "Отправлено с сайта", lang ?? "es")] = "";
    for (var propName in params) {
      if (propName && !ignoredParams.includes(propName)) {
        props[propName] = params[propName];
        vprops.push({ key: propName, value: params[propName] });
      }
    }
    setOrderProps(props);
    setVisualProps(vprops);
  }, []);

  const mameWaiter = (visualProps.map(e => e.value).toString().replace(",", ""));
  const utmParams = `${url}?${__("text0018")}=${number}&iamwaiter=${mameWaiter}`;
  const translatedText = translatedProperty({ text, textEn, textEs, textRu, textDe, textFr, textIt, textZh }, "text");
  const translatedTextAlt = translatedProperty({ textAlt, textAltEn, textAltEs, textAltRu, textAltDe, textAltFr, textAltIt, textAltZh }, "textAlt");

  const imageCard = image;

  const statusСalculator = {
    newLength: (dataOrders.filter(order => order.status === "new"
      && order.tableNumber === Number(number)
    )).length,
    activeLength: (dataOrders.filter(order => order.status === "active"
      && order.tableNumber === Number(number)
    )).length
  };

  switch (type) {
    case CATALOG_TABLE_LEFT:
    default: {
      const style = {};
      const catalogTable = (
        <div
          className={classnames([
            'catalog-table',
            className
          ])}
          style={style}
          key={guid}
        >



          {!unavailableForBooking &&
            <>
            {image && (number) && (
              <img src={image} alt={text} />
            )}

              <div onClick={onClick} className="catalog-table__title-box">
                <div className="catalog-table__title">{number}</div>
                {(statusСalculator.activeLength > 0 || statusСalculator.newLength > 0) &&
                  <div className="catalog-table__title-box__center-line" />}
                <div className="catalog-table__center-box">
                  {(statusСalculator.activeLength > 0 || statusСalculator.newLength > 0) &&
                    <div style={{ borderColor: "red", marginRight: 6 }}
                      className="catalog-table__center">
                      <b>{statusСalculator.newLength}</b>
                    </div>}
                  {(statusСalculator.activeLength > 0 || statusСalculator.newLength > 0) &&
                    <div style={{ borderColor: "rgb(209, 179, 8)", marginRight: 6 }}
                      className="catalog-table__center">
                      <b>{statusСalculator.activeLength}</b>
                    </div>}
                </div>
              </div>
            </>}

          {unavailableForBooking &&
            <div onClick={onClick} className="catalog-table__title-box">
              <div className="catalog-table__title-box__title">{number}</div>
              <div className="catalog-table__title-box__center-line" />
              <div className="catalog-table__title-box__sub-title">{__("text0042")}</div>
            </div>
          }

          {!unavailableForBooking && <div onClick={() => window.open(utmParams, "_self")} className="catalog-table__right"><Icon type="plus" /></div>}
          {/* {unavailableForBooking && <div className="catalog-table__right__unavalible"><Icon type="plus" /></div>} */}

        </div >
      );

      return (
        <div >
          <Button className="button-in-catalog-table " isPulse={animation} technical={technical}>
            {catalogTable}
          </Button>
        </div>
      );
    }
  }
};

CatalogTable.propTypes = {
  guid: PropTypes.string,
  animation: PropTypes.bool,
  image: PropTypes.string,
  text: PropTypes.string,
  textEn: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
  number: PropTypes.number,
  outOfStock: PropTypes.bool,
  type: PropTypes.string,
  technical: PropTypes.bool,
  refObj: PropTypes.object
};

CatalogTable.defaultProps = {
  animation: false,
  image: undefined,
  text: '',
  textEn: '',
  price: undefined,
  currency: '',
  number: undefined,
  outOfStock: false,
  type: undefined,
  technical: false,
  refObj: undefined
};

export default CatalogTable;
