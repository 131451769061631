import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MinuteCounter from '../MinuteCounter';
import { __ } from 'utils/translation';
import Button from 'components/common/Button';
import Textarea from 'components/common/Textarea';
import { getSearchParams } from 'utils/url';

import './index.scss';


import {
    STATUS_NEW,
    STATUS_ACTIVE,
    STATUS_COMPLETED,
    STATUS_CANCELLED
} from 'constants/orderTablesTypes';

const OrderTables = (props) => {
    const {
        status,
        date,
        id,
        json
    } = props;

    const [activate, setActivate] = useState(false);
    const [complete, setComplete] = useState(false);
    const [cancel, setCancel] = useState(false);

    const [inputForActivate, setInputForActivate] = useState(false);
    const [commentForActivate, setCommentForActivate] = useState("");

    const [inputForComplete, setInputForComplete] = useState(false);
    const [commentForComplete, setCommentForComplete] = useState("");

    const [inputForCancel, setInputForCancel] = useState(false);
    const [commentForCancel, setCommentForCancel] = useState("");

    const [sent, setSent] = useState(false);

    var params = getSearchParams(window.location.search);
    const [orderProps, setOrderProps] = useState({});
    const [visualProps, setVisualProps] = useState([]);
    const { lang } = useSelector((state) => state.config.config);

    useEffect(() => {
        const isPwa = params['pwa'] === "" || params['pwa'];

        const ignoredParams = ["pwa", "demo"];
        const props = {}
        const vprops = [];
        props[__(isPwa ? "Отправлено из установленного приложения" : "Отправлено с сайта", lang ?? "es")] = "";
        for (var propName in params) {
            if (propName && !ignoredParams.includes(propName)) {
                props[propName] = params[propName];
                vprops.push({ key: propName, value: params[propName] });
            }
        }
        setOrderProps(props);
        setVisualProps(vprops);
    }, []);

    const nameWaiter = (visualProps.slice(1, 2).map(e => e.value).toString().replace(",", ""));
        console.log("nameWaiter orderTbles", nameWaiter);


    const handleRePrinting = () => {
        setSent(true)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
            })
        };
        fetch(`https://api.maps.menu/api/order/resend/${id}/`, requestOptions)
            .then(response => response.json())
            setTimeout(() => {
                setSent(false)
              }, 10000);
        return () => {
        }
    }

    const fullCommentForActivate  = `${commentForActivate}, ${__("text0017")}: ${nameWaiter}`;
    const fullCommentForComplete  = `${commentForComplete}, ${__("text0017")}: ${nameWaiter}`;
    const fullCommentForCancel  = `${commentForCancel}, ${__("text0017")}: ${nameWaiter}`;

    const handleActivate = () => {
        setInputForActivate(false);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                status: "active",
                hours: 24,
                comment: fullCommentForActivate
            })
        };
        fetch(`https://api.maps.menu/api/orders/status/${id}/`, requestOptions)
            .then(response => response.json())
            .then(json => setActivate(json))
        return () => {
            console.log('clean type')
        }
    };

    const handleComplete = () => {
        setInputForComplete(false);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                status: "completed",
                hours: 48,
                comment: fullCommentForComplete
            })
        };
        fetch(`https://api.maps.menu/api/orders/status/${id}/`, requestOptions)
            .then(response => response.json())
            .then(json => setComplete(json))
        return () => {
            console.log('clean type')
        }
    };

    const handleCancel = () => {
        setInputForCancel(false);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                status: "cancelled",
                hours: 48,
                comment: fullCommentForCancel
            })
        };
        fetch(`https://api.maps.menu/api/orders/status/${id}/`, requestOptions)
            .then(response => response.json())
            .then(json => setCancel(json))
        return () => {
            console.log('clean type')
        }
    };

    var OrderTable = null;

    if (activate || complete || cancel)
        return null;

    switch (status) {
        case STATUS_NEW: {

            OrderTable = (
                <>
                    <div className="order__screenshot-area">
                        <div className="order-flex-row-start">
                            <div className="order__prop-date">{__("text0018")}:&nbsp;</div>
                            <div className="order__prop-date">{json.tabelNumber}</div>
                        </div>
                        {json.namewaiter && <div className="order-flex-row-start">
                            <div className="order__prop-date">{__("text0017")}:&nbsp;</div>
                            <div className="order__prop-date">{json.namewaiter}</div>
                        </div>}
                        <div className="order-flex-row-start">
                            <div className="order__prop-date">{__("text0019")}&nbsp;<b>{status}</b>&nbsp;{__("text0025")}&nbsp;</div>
                            <div style={{ maxWidth: "30%" }} className="order__prop-date"><MinuteCounter date={date} />&nbsp;min ago</div>
                        </div>
                        <div className="order-flex-row">
                            <div className="order__prop">{__("text0020")}:&nbsp;</div>
                            <div className="order__prop"><b>{id}</b></div>
                        </div>
                        <br />

                        {json.items.map(orderItem => {
                            return (

                                <div className="order__description" key={orderItem.id}>

                                    {orderItem?.number ? <>&#8470;<b>{orderItem?.number}</b> | </> : null}{orderItem?.text}
                                    &nbsp;
                                    {orderItem?.description}
                                    : &nbsp; <b>
                                        {orderItem.count}
                                    </b> x &nbsp;
                                    {orderItem?.price}
                                    {orderItem.currency}
                                    =
                                    {orderItem.sum}
                                    {orderItem.currency}
                                </div>
                            );
                        })}
                        {json.comment && <div className="order__description"><b>{__("text0021")}</b>&nbsp; {json.comment}</div>}
                        <div className="order__total">{__("text0022")} {json.total} {json.currency}</div>
                        <br />
                        <div className="catalogTablesSettings-order-buttons-box">
                            {!inputForActivate && !inputForComplete && !inputForCancel && 
                                <Button noStyled onClick={() => setInputForActivate(true)}
                                    className="catalogTablesSettings-order-buttons-active" >
                                    {__("text0026")}:&nbsp; {id}
                                </Button>}
                            {inputForActivate &&
                                <>
                                    <div className="orders-new-and-active-input-box">
                                        <Button noStyled onClick={handleActivate}
                                            className="catalogTablesSettings-order-buttons-active pulse" >
                                            {__("text0026")}:&nbsp; {id}
                                        </Button>
                                        <Textarea
                                            className="order__input"
                                            value={commentForActivate}
                                            type="text"
                                            placeholder={__("text0027")}
                                            onChange={(value) => setCommentForActivate(value)}
                                        />
                                        <div className="order__input__descriptions-minus-margin"></div>
                                        <ul>
                                            <li
                                                onClick={() => setCommentForActivate(__("text0032"))}
                                                className="orders-new-and-active-input-quick-answers">{__("text0032")}</li>
                                            <li
                                                onClick={() => setCommentForActivate(__("text0033"))}
                                                className="orders-new-and-active-input-quick-answers">{__("text0033")}</li>
                                        </ul>
                                        <div className="order__input__descriptions-minus-margin"></div>
                                        <div className="order__input__descriptions">{__("text0031")}</div>
                                    </div>
                                </>}


                            {!inputForActivate && !inputForComplete && !inputForCancel &&
                                <Button noStyled onClick={() => setInputForComplete(true)}
                                    className="catalogTablesSettings-order-buttons-completed" >
                                    {__("text0034")}:&nbsp; {id}
                                </Button>}
                            {inputForComplete &&
                                <>
                                    <div className="orders-new-and-active-input-box">
                                        <Button noStyled onClick={handleComplete}
                                            className="catalogTablesSettings-order-buttons-completed pulse" >
                                            {__("text0034")}:&nbsp; {id}
                                        </Button>
                                        <Textarea
                                            className="order__input"
                                            value={commentForComplete}
                                            type="text"
                                            placeholder={__("text0027")}
                                            onChange={(value) => setCommentForComplete(value)}
                                        />
                                        <div className="order__input__descriptions-minus-margin"></div>
                                        <ul>
                                            <li
                                                onClick={() => setCommentForComplete(__("text0029"))}
                                                className="orders-new-and-active-input-quick-answers">{__("text0029")}</li>
                                            <li
                                                onClick={() => setCommentForComplete(__("text0030"))}
                                                className="orders-new-and-active-input-quick-answers">{__("text0030")}</li>
                                        </ul>
                                        <div className="order__input__descriptions-minus-margin"></div>
                                        <div className="order__input__descriptions">{__("text0031")}</div>
                                    </div>
                                </>}


                            {!inputForActivate && !inputForComplete && !inputForCancel &&
                                <Button noStyled onClick={() => setInputForCancel(true)}
                                    className="catalogTablesSettings-order-buttons-cancelled" >
                                    {__("text0028")}:&nbsp; {id}
                                </Button>}
                            {inputForCancel &&
                                <>
                                    <div className="orders-new-and-active-input-box">
                                        <Button noStyled onClick={handleCancel}
                                            className="catalogTablesSettings-order-buttons-cancelled pulse" >
                                            {__("text0028")}:&nbsp; {id}
                                        </Button>
                                        <Textarea
                                            className="order__input"
                                            value={commentForCancel}
                                            type="text"
                                            placeholder={__("text0027")}
                                            onChange={(value) => setCommentForCancel(value)}
                                        />
                                        <div className="order__input__descriptions-minus-margin"></div>
                                        <ul>
                                            <li
                                                onClick={() => setCommentForCancel(__("text0035"))}
                                                className="orders-new-and-active-input-quick-answers">{__("text0035")}</li>
                                            <li
                                                onClick={() => setCommentForCancel(__("text0036"))}
                                                className="orders-new-and-active-input-quick-answers">{__("text0036")}</li>
                                        </ul>
                                        <div className="order__input__descriptions-minus-margin"></div>
                                        <div className="order__input__descriptions">{__("text0031")}</div>
                                    </div>
                                </>}


                            {sent ?
                                <Button style={{ width: "100%", marginBottom: 12 }}>
                                    {__("text0023")}&nbsp;...
                                </Button>
                                :
                                <Button onClick={handleRePrinting}
                                    className="catalogTablesSettings-order-buttons-cancelled" >
                                    {__("text0024")}:&nbsp; {id}
                                </Button>
                            }

                        </div>
                    </div>

                </>
            );
            break;
        }

        case STATUS_ACTIVE: {
            OrderTable = (
                <>
                    <div className="order__screenshot-area">
                        <div className="order-flex-row-start">
                            <div className="order__prop-date">{__("text0018")}:&nbsp;</div>
                            <div className="order__prop-date">{json.tabelNumber}</div>
                        </div>
                        {json.namewaiter && <div className="order-flex-row-start">
                            <div className="order__prop-date">{__("text0017")}:&nbsp;</div>
                            <div className="order__prop-date">{json.namewaiter}</div>
                        </div>}
                        <div className="order-flex-row-start">
                            <div className="order__prop-date">{__("text0019")}&nbsp;<b>{status}</b>&nbsp;{__("text0025")}&nbsp;</div>
                            <div style={{ maxWidth: "30%" }} className="order__prop-date"><MinuteCounter date={date} />&nbsp;min ago</div>
                        </div>
                        <div className="order-flex-row">
                            <div className="order__prop">{__("text0020")}:&nbsp;</div>
                            <div className="order__prop"><b>{id}</b></div>
                        </div>
                        <br />

                        {json.items.map(orderItem => {
                            return (

                                <div className="order__description" key={orderItem.id}>

                                    {orderItem?.number ? <>&#8470;<b>{orderItem?.number}</b> | </> : null}{orderItem?.text}
                                    &nbsp;
                                    {orderItem?.description}
                                    : &nbsp; <b>
                                        {orderItem.count}
                                    </b> x &nbsp;
                                    {orderItem?.price}
                                    {orderItem.currency}
                                    =
                                    {orderItem.sum}
                                    {orderItem.currency}
                                </div>
                            );
                        })}
                        {json.comment && <div className="order__description"><b>{__("text0021")}</b>&nbsp; {json.comment}</div>}
                        <div className="order__total">{__("text0022")} {json.total} {json.currency}</div>
                        <br />
                        <div className="catalogTablesSettings-order-buttons-box">
                            { !inputForComplete && !inputForCancel &&
                                <Button noStyled onClick={() => setInputForComplete(true)}
                                    className="catalogTablesSettings-order-buttons-completed" >
                                    {__("text0034")}:&nbsp; {id}
                                </Button>}
                            {inputForComplete &&
                                <>
                                    <div className="orders-new-and-active-input-box">
                                        <Button noStyled onClick={handleComplete}
                                            className="catalogTablesSettings-order-buttons-completed pulse" >
                                            {__("text0034")}:&nbsp; {id}
                                        </Button>
                                        <Textarea
                                            className="order__input"
                                            value={commentForComplete}
                                            type="text"
                                            placeholder={__("text0027")}
                                            onChange={(value) => setCommentForComplete(value)}
                                        />
                                        <div className="order__input__descriptions-minus-margin"></div>
                                        <ul>
                                            <li
                                                onClick={() => setCommentForComplete(__("text0029"))}
                                                className="orders-new-and-active-input-quick-answers">{__("text0029")}</li>
                                            <li
                                                onClick={() => setCommentForComplete(__("text0030"))}
                                                className="orders-new-and-active-input-quick-answers">{__("text0030")}</li>
                                        </ul>
                                        <div className="order__input__descriptions-minus-margin"></div>
                                        <div className="order__input__descriptions">{__("text0031")}</div>
                                    </div>
                                </>}


                            {!inputForComplete && !inputForCancel &&
                                <Button noStyled onClick={() => setInputForCancel(true)}
                                    className="catalogTablesSettings-order-buttons-cancelled" >
                                    {__("text0028")}:&nbsp; {id}
                                </Button>}
                            {inputForCancel &&
                                <>
                                    <div className="orders-new-and-active-input-box">
                                        <Button noStyled onClick={handleCancel}
                                            className="catalogTablesSettings-order-buttons-cancelled pulse" >
                                            {__("text0028")}:&nbsp; {id}
                                        </Button>
                                        <Textarea
                                            className="order__input"
                                            value={commentForCancel}
                                            type="text"
                                            placeholder={__("text0027")}
                                            onChange={(value) => setCommentForCancel(value)}
                                        />
                                        <div className="order__input__descriptions-minus-margin"></div>
                                        <ul>
                                            <li
                                                onClick={() => setCommentForCancel(__("text0035"))}
                                                className="orders-new-and-active-input-quick-answers">{__("text0035")}</li>
                                            <li
                                                onClick={() => setCommentForCancel(__("text0036"))}
                                                className="orders-new-and-active-input-quick-answers">{__("text0036")}</li>
                                        </ul>
                                        <div className="order__input__descriptions-minus-margin"></div>
                                        <div className="order__input__descriptions">{__("text0031")}</div>
                                    </div>
                                </>}


                            {sent ?
                                <Button style={{ width: "100%", marginBottom: 12 }}>
                                    {__("text0023")}&nbsp;...
                                </Button>
                                :
                                <Button onClick={handleRePrinting}
                                    className="catalogTablesSettings-order-buttons-cancelled" >
                                    {__("text0024")}:&nbsp; {id}
                                </Button>
                            }

                        </div>
                    </div>

                </>
            );
            break;
        }

        case STATUS_COMPLETED: {
            OrderTable = (
                <>
                    <div className="order__screenshot-area">
                        <div className="order-flex-row-start">
                            <div className="order__prop-date">{__("text0018")}:&nbsp;</div>
                            <div className="order__prop-date">{json.tabelNumber}</div>
                        </div>
                        {json.namewaiter && <div className="order-flex-row-start">
                            <div className="order__prop-date">{__("text0017")}:&nbsp;</div>
                            <div className="order__prop-date">{json.namewaiter}</div>
                        </div>}
                        <div className="order-flex-row-start">
                            <div className="order__prop-date">{__("text0019")}&nbsp;<b>{status}</b>&nbsp;{__("text0025")}&nbsp;</div>
                            <div style={{ maxWidth: "30%" }} className="order__prop-date"><MinuteCounter date={date} />&nbsp;min ago</div>
                        </div>
                        <div className="order-flex-row">
                            <div className="order__prop">{__("text0020")}:&nbsp;</div>
                            <div className="order__prop"><b>{id}</b></div>
                        </div>
                        <br />

                        {json.items.map(orderItem => {
                            return (

                                <div className="order__description" key={orderItem.id}>

                                    {orderItem?.number ? <>&#8470;<b>{orderItem?.number}</b> | </> : null}{orderItem?.text}
                                    &nbsp;
                                    {orderItem?.description}
                                    : &nbsp; <b>
                                        {orderItem.count}
                                    </b> x &nbsp;
                                    {orderItem?.price}
                                    {orderItem.currency}
                                    =
                                    {orderItem.sum}
                                    {orderItem.currency}
                                </div>
                            );
                        })}
                        {json.comment && <div className="order__description"><b>{__("text0021")}</b>&nbsp; {json.comment}</div>}
                        <div className="order__total">{__("text0022")} {json.total} {json.currency}</div>
                        <br />
                        <div className="catalogTablesSettings-order-buttons-box">

                            {sent ?
                                <Button style={{ width: "100%", marginBottom: 12 }}>
                                    {}&nbsp;...
                                </Button>
                                :
                                <Button onClick={handleRePrinting}
                                    className="catalogTablesSettings-order-buttons-cancelled" >
                                    {__("text0024")}:&nbsp; {id}
                                </Button>
                            }

                        </div>
                    </div>

                </>
            );
            break;
        }

        case STATUS_CANCELLED:
        default: {
            OrderTable = (
                <>
                    <div className="order__screenshot-area">
                        <div className="order-flex-row-start">
                            <div className="order__prop-date">{__("text0018")}:&nbsp;</div>
                            <div className="order__prop-date">{json.tabelNumber}</div>
                        </div>
                        {json.namewaiter && <div className="order-flex-row-start">
                            <div className="order__prop-date">{__("text0017")}:&nbsp;</div>
                            <div className="order__prop-date">{json.namewaiter}</div>
                        </div>}
                        <div className="order-flex-row-start">
                            <div className="order__prop-date">{__("text0019")}&nbsp;<b>{status}</b>&nbsp;{__("text0025")}&nbsp;</div>
                            <div style={{ maxWidth: "30%" }} className="order__prop-date"><MinuteCounter date={date} />&nbsp;min ago</div>
                        </div>
                        <div className="order-flex-row">
                            <div className="order__prop">{__("text0020")}:&nbsp;</div>
                            <div className="order__prop"><b>{id}</b></div>
                        </div>
                        <br />

                        {json.items.map(orderItem => {
                            return (

                                <div className="order__description" key={orderItem.id}>

                                    {orderItem?.number ? <>&#8470;<b>{orderItem?.number}</b> | </> : null}{orderItem?.text}
                                    &nbsp;
                                    {orderItem?.description}
                                    : &nbsp; <b>
                                        {orderItem.count}
                                    </b> x &nbsp;
                                    {orderItem?.price}
                                    {orderItem.currency}
                                    =
                                    {orderItem.sum}
                                    {orderItem.currency}
                                </div>
                            );
                        })}
                        {json.comment && <div className="order__description"><b>{__("text0021")}</b>&nbsp; {json.comment}</div>}
                        <div className="order__total">{__("text0022")} {json.total} {json.currency}</div>
                        <br />
                        <div className="catalogTablesSettings-order-buttons-box">


                            {sent ?
                                <Button style={{ width: "100%", marginBottom: 12 }}>
                                    {__("text0023")}&nbsp;...
                                </Button>
                                :
                                <Button onClick={handleRePrinting}
                                    className="catalogTablesSettings-order-buttons-cancelled" >
                                    {__("text0024")}:&nbsp; {id}
                                </Button>
                            }

                        </div>
                    </div>

                </>
            );
            break;
        }
    }

    return OrderTable;

};

OrderTables.propTypes = {
    type: PropTypes.string,
};

OrderTables.defaultProps = {
    type: undefined

};

export default OrderTables;
