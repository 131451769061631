import React, { useState, useEffect } from 'react';
import OrdersNewAndActive from '../OrdersNewAndActive';
import { __ } from 'utils/translation';
import PropTypes from 'prop-types';
import './index.scss';

const OrdersAll = ({dataOrders, type}) => {
  dataOrders.sort((a, b) => b.id - a.id);
    return (
        <div className="orders-all-box">
          {__("text0037")}:&nbsp;{type}
          <div className="catalogTablesSettings-orders">
                    {
                        dataOrders.map((OrderTable) => <OrdersNewAndActive 
                        key={OrderTable._id} 
                        {...OrderTable} 
                        sort={type} />)
                    }
                </div>
        </div>
      );
}

OrdersAll.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({}))
  };
  
  OrdersAll.defaultProps = {
    data: []
  };
  
  export default OrdersAll;