import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { __ } from 'utils/translation';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import imageEmpty from 'images/no-image.jpg';
import './index.scss';
import { CATALOG_ORDER, CATALOG_FILTER_HEADER } from 'constants/actions';
import { translatedProperty } from 'utils/translation';
import allergy_altramuces from 'images/allergy_altramuces.png';
import allergy_apio from 'images/allergy_apio.png';
import allergy_cacahuetas from 'images/allergy_cacahuetas.png';
import allergy_crustaseos from 'images/allergy_crustaseos.png';
import allergy_dioxido from 'images/allergy_dioxido.png';
import allergy_gluten from 'images/allergy_gluten.png';
import allergy_huevos from 'images/allergy_huevos.png';
import allergy_leche from 'images/allergy_leche.png';
import allergy_moluscos from 'images/allergy_moluscos.png';
import allergy_mostaza from 'images/allergy_mostaza.png';
import allergy_nuts from 'images/allergy_nuts.png';
import allergy_pescados from 'images/allergy_pescados.png';
import allergy_sesamo from 'images/allergy_sesamo.png';
import allergy_soja from 'images/allergy_soja.png'; 

const CatalogItemTablet = ({
  isBookNoFoto, text, textAlt, additionally, 
  textAz, textBn, textCa, textGa, textHi, textHr, textHy, textIs, textKa, textKk, textMn, textNo, textSr, textSq, textTg, textTk, textTr, textUk, textUz, textVi, textHu, textEl, textDa, textLv, textLt, textNl, textPl, textRo, textSk,  textSl, textFi, textCs, textSv, textEt, textPt, textBg, textEn, textRu, textEs, textIt, textDe, textFr, textJa, textZh,
  
  textAltAz, textAltBn, textAltCa, textAltGa, textAltHi, textAltHr,textAltHy, textAltIs, textAltKa, textAltKk, textAltMn, textAltNo, textAltSr, textAltSq, textAltTg, textAltTk, textAltTr, textAltUk, textAltUz, textAltVi, textAltHu, textAltEl, textAltDa, textAltLv, textAltLt, textAltNl, textAltPl, textAltRo, textAltSk, textAltSl, textAltFi, textAltCs, textAltSv, textAltEt, textAltPt, textAltBg,textAltEn, textAltRu, textAltEs, textAltIt, textAltDe, textAltFr,textAltJa, textAltZh,
  
  onClick, guid, animation, image, price, currency, number, type, className, technical, index, reviews,
  allergySoja, allergyPescado, allergyMostaza, allergyMoluscos, allergyGluten, allergyCascara, allergyApio,
  allergySulfitos, allergyCrustaseos, allergyAltramuces, allergyLacteos, allergyHuevos, allergySesamo, allergyCacahuetes
}) => {
  const dispatch = useDispatch();
  const { count, sum } = useSelector((state) => state.config.order[guid] ?? { count: 0, sum: 0 });
  const { headerGuid } = useSelector((state) => state.config);


  const translatedText = translatedProperty({ text, textAz, textBn, textCa, textGa, textHi, textHr, textHy, textIs, textKa, textKk, textMn, textNo, textSr, textSq, textTg, textTk, textTr, textUk, textUz, textVi, textHu, textEl, textDa, textLv, textLt, textNl, textPl, textRo, textSk, textSl, textFi, textCs, textSv, textEt, textPt, textBg, textEn, textEs, textRu, textDe, textFr, textIt, textJa, textZh }, "text");
  const translatedTextAlt = translatedProperty({ textAlt, textAltAz, textAltBn, textAltCa, textAltGa, textAltHi, textAltHr, textAltHy, textAltIs, textAltKa, textAltKk, textAltMn, textAltNo, textAltSr, textAltSq, textAltTg, textAltTk, textAltTr, textAltUk, textAltUz, textAltVi, textAltHu, textAltEl, textAltDa, textAltLv, textAltLt, textAltNl, textAltPl, textAltRo, textAltSk, textAltSl, textAltFi, textAltCs, textAltSv, textAltEt, textAltPt, textAltBg, textAltEn, textAltEs, textAltRu, textAltDe, textAltFr, textAltIt, textAltJa, textAltZh }, "textAlt");

  const handleHeaderClick = () => {
    dispatch({ type: CATALOG_FILTER_HEADER, headerGuid: headerGuid === guid ? null : guid });
  }

  let useAllergyButtons = false;
  if (
    (allergySoja || false) || (allergyPescado || false) || (allergyMostaza || false) || (allergyMoluscos || false) || (allergyGluten || false) ||
    (allergyCascara || false) || (allergyApio || false) || (allergySulfitos || false) || (allergyCrustaseos || false) || (allergyAltramuces || false) ||
    (allergyLacteos || false) || (allergyHuevos || false) || (allergySesamo || false) || (allergyCacahuetes || false)) {
    useAllergyButtons = true;
  };


  const nameEmptyItem = "";
  const handlePlus = (e) => {
    e.stopPropagation();
    dispatch({
      type: CATALOG_ORDER, guid, count: count + 1,
      sum: sum + parseFloat(price), currency, nameEmptyItem
    });
  };
  const handleMinus = (e) => {
    e.stopPropagation();
    if (count === 0) {
      return;
    }
    dispatch({
      type: CATALOG_ORDER, guid, count: count - 1,
      sum: sum - parseFloat(price), currency, nameEmptyItem
    });
  };

  const handleChecked = (e) => {
    if (count === 0) {
      handlePlus(e);
    } else {
      handleMinus(e);
    }
  }


  const sumValue = count !== 0 ? sum.toFixed(2) :
    parseFloat(price).toFixed(2); 


  // const reviewImage = (reviews?.filter(r => r.isActive) ?? []).reverse().filter(r => r.image)[0]?.image;
  // const imageCard = image ?? reviewImage;

 

  const catalogItemFoto = (  
        <div className='catalogItemTablet' key={guid} onClick={onClick}
        >
          <img src={image || imageEmpty} alt={translatedText} />
          
          {(translatedText && !translatedTextAlt) && (price || number) && (
            <div className="catalogItemTablet-preorder-flex-column">
              <div className="catalogItem-price-empty"></div>
              <div className="catalogItemTablet-title">{translatedText}</div>
              <div className="catalogItem-price-empty"></div>
            </div>
          )}

          {(translatedText && translatedTextAlt) && (price || number) && (
            <div className="catalogItemTablet-preorder-flex-column">
              <div className="catalogItemTablet-title-with-textEn">{translatedText}</div>
              <div className="catalogItemTablet-text-en">{translatedTextAlt}</div>
            </div>
          )}

          <div className="catalogItemTablets-price">•&nbsp;{price}&nbsp;{ currency || ""}</div>
          {(translatedText ) && (price || number) && (
            <div className="catalogItem-preorder-flex-column">
              <div className="catalogItemTablet-allegy-box">
                {allergySoja && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_soja})` }}></div>}
                {allergyGluten && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_gluten})` }}></div>}
                {allergyPescado && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_pescados})` }}></div>}
                {allergyMostaza && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_mostaza})` }}></div>}
                {allergyMoluscos && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_moluscos})` }}></div>}
                {allergyCascara && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_nuts})` }}></div>}
                {allergyApio && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_apio})` }}></div>}
                {allergySulfitos && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_dioxido})` }}></div>}
                {allergyCrustaseos && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_crustaseos})` }}></div>}
                {allergyAltramuces && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_altramuces})` }}></div>}
                {allergyLacteos && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_leche})` }}></div>}
                {allergyHuevos && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_huevos})` }}></div>}
                {allergySesamo && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_sesamo})` }}></div>}
                {allergyCacahuetes && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_cacahuetas})` }}></div>}

              </div>
            </div>
          )}
        </div>

            
    
    
      );

      const catalogItemNoFoto = (  
        <div className='catalogItemTablet' key={guid} onClick={onClick}
        >
                   
          {(translatedText && !translatedTextAlt) && (price || number) && (
            <div className="catalogItemNoFoto-preorder-flex-column">
              <div className="catalogItemNoFoto-preorder-flex-row">
                <div className="catalogItemTablet-title">{translatedText}</div>               

                {image && <div className="catalogItemNoFoto-camera"><Icon type="camera" /></div>} 
                {additionally && <div className="catalogItemNoFoto-additionally">•&nbsp;{additionally}</div>}
                <div className="catalogItemNoFoto-price">•&nbsp;{price}&nbsp;{ currency || ""}</div>                  
                  
              </div>
            </div>
          )}

          {(translatedText && translatedTextAlt) && (price || number) && (
            <div className="catalogItemNoFoto-preorder-flex-column">
              <div className="catalogItemNoFoto-preorder-flex-row">
                <div className="catalogItemTablet-title">{translatedText}</div>

                {image && <div className="catalogItemNoFoto-camera"><Icon type="camera"  /></div>}
                {additionally && <div className="catalogItemNoFoto-additionally">•&nbsp;{additionally}</div>}
                <div className="catalogItemNoFoto-price">•&nbsp;{price}&nbsp;{ currency || ""}</div>
                  
                </div>
              <div className="catalogItemTablet-text-en">{translatedTextAlt}</div>
            </div>
          )}
          {(translatedText ) && (price || number) && (
            <div className="catalogItem-preorder-flex-column">
              <div className="catalogItemTablet-allegy-box">
                {allergySoja && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_soja})` }}></div>}
                {allergyGluten && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_gluten})` }}></div>}
                {allergyPescado && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_pescados})` }}></div>}
                {allergyMostaza && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_mostaza})` }}></div>}
                {allergyMoluscos && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_moluscos})` }}></div>}
                {allergyCascara && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_nuts})` }}></div>}
                {allergyApio && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_apio})` }}></div>}
                {allergySulfitos && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_dioxido})` }}></div>}
                {allergyCrustaseos && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_crustaseos})` }}></div>}
                {allergyAltramuces && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_altramuces})` }}></div>}
                {allergyLacteos && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_leche})` }}></div>}
                {allergyHuevos && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_huevos})` }}></div>}
                {allergySesamo && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_sesamo})` }}></div>}
                {allergyCacahuetes && <div className="catalogItemTablet-allegy-items" style={{ backgroundImage: `URL(${allergy_cacahuetas})` }}></div>}

              </div>
            </div>
          )}
        </div>
      );  

      // if ((price ) && index === 0 && !isBookNoFoto)
      //   return (
      //       <Button className="button-in-catalogItemTablet " isPulse={true} technical={technical}>
      //         <div className="story-clickme">{__("Нажми")}</div>
      //         {catalogItemFoto}
      //       </Button>
      //   );

      if ((price )  && !isBookNoFoto)
        return (
            <Button className="button-in-catalogItemTablet " isPulse={animation} technical={technical}>
              {catalogItemFoto}
            </Button>
        );

        if ((price )  && isBookNoFoto)
        return (
            <div className="catalogItemTabletNoFoto" isPulse={animation} technical={technical}>
              {catalogItemNoFoto}
            </div>
        );  


        if (!price && image)
        
        return (
          <div className='printer-ratio-box'>
            <div className='printer-video-box' style={{ backgroundImage: `URL(${image})` }}/>
          </div>
        );
        
        return null;
};

CatalogItemTablet.propTypes = {
  guid: PropTypes.string,
  animation: PropTypes.bool,
  image: PropTypes.string,
  text: PropTypes.string,
  textEn: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
  number: PropTypes.number,
  outOfStock: PropTypes.bool,
  type: PropTypes.string,
  technical: PropTypes.bool,
  refObj: PropTypes.object
};

CatalogItemTablet.defaultProps = {
  animation: false,
  image: undefined,
  text: '',
  textEn: '',
  price: undefined,
  currency: '',
  number: undefined,
  outOfStock: false,
  type: undefined,
  technical: false,
  refObj: undefined
};

export default CatalogItemTablet;
