import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { trackWindowScroll, LazyLoadComponent } from 'react-lazy-load-image-component';
import { useCookies } from 'react-cookie';
import { uuid } from 'uuidv4';
import CatalogTable from './CatalogTable';
import CatalogTableSettings from './CatalogTableSettings';
import Slider from 'components/common/Slider';
import { __ } from 'utils/translation';
import Button from 'components/common/Button';
import OrdersAll from './OrdersAll'
import { SCROLL_CATALOG_ID } from 'constants/scrolls';

import './index.scss';

const emptySettings = {
  guid: null,
  type: 'preview-text',
  text: '',
  description: '',
  image: '',
  textEn: '',
  descriptionEn: '',
};
const CatalogTables = ({ data, profile, scrollPosition }) => {
  const [settingsOpened, setSettingsOpened] = useState(null);
  const [catalogTableData, setCatalogTableData] = useState(emptySettings);
  const [cookies] = useCookies();
  const { active } = useSelector((state) => state.config.account);
  const { catalogTables = [] } = useSelector((state) => state.config.data);
  const { storiesTables = [] } = useSelector((state) => state.config.data);
  const { storyTableGuidSelected } = useSelector((state) => state.config);

  const [type, setType] = useState('new');
  const [dataOrders, setDataOrders] = useState([]);
  const { settingsName } = useSelector((state) => state.config.data);

  const [timer, setTimer] = useState(null);
  const [newRequest, setNewRequest] = useState(0);

  useEffect(() => {
    const currentCatalogTable = data.find((catalogTable) => catalogTable.guid === settingsOpened);
    const settings = currentCatalogTable || { ...emptySettings };
    if (!settings.guid)
      settings.guid = uuid();
    setCatalogTableData({ ...settings });
  }, [settingsOpened, data]);

  const closeCatalogTablesSettings = () => {
    setSettingsOpened(null);
  };

  const onOpenCatalogTableSettings = (catalogTableId) => {
    setSettingsOpened(catalogTableId);
  };

  var inviteId = cookies[profile];
  if (inviteId === "undefined") {
    inviteId = null;
  }

  useEffect(() => {
    const currentCatalogTable = data.find((catalogTable) => catalogTable.guid === settingsOpened);
    const settings = currentCatalogTable || { ...emptySettings };
    if (!settings.guid)
      settings.guid = uuid();
    setCatalogTableData({ ...settings });
  }, [settingsOpened, data]);

  useEffect(() => {
    timer && timer > 0 && setTimeout(() => {
      if (!timer || timer <= 1) {
        setTimer(null);
        setNewRequest(newRequest + 1)
        return;
      }
      setTimer(timer - 1);
    }, 10000);
  }, [timer]);

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        hours: 48
      })
    };
    fetch(`https://api.maps.menu/api/orders/params/${settingsName}/`, requestOptions)
      .then(response => response.json())
      .then(json => setDataOrders(json))
      .then(() => {
        setTimer(1);
      })
    return () => {

    }
  }, [newRequest, type]);

  const hour = new Date().getHours();
  const checkCatalogTable = (e) => {
    return (storyTableGuidSelected || (storiesTables?.length ?? 0) === 0)
      && (!storyTableGuidSelected || !e.storyGuid || e.storyGuid === storyTableGuidSelected)
       ;
  };

  const noCheckCatalogTable = (e) => {
    return (storyTableGuidSelected || !storyTableGuidSelected);
  };

  data.sort((a, b) => b.order - a.order);

  const statusСalculator = {
    newLength: (dataOrders.filter(order => order.status === "new")).length,
    activeLength: (dataOrders.filter(order => order.status === "active")).length
  };

  return (
    <React.Fragment>

      {storiesTables.length === 1 &&
        <>
          <div id={SCROLL_CATALOG_ID}>
            <br />
            <div style={{ textAlign: "center" }}>{__("text0016")?.toUpperCase()}</div>
            {
              data.filter(noCheckCatalogTable).map((catalogTable) =>
                <LazyLoadComponent key={catalogTable.guid} scrollPosition={scrollPosition} threshold={10}>
                  <CatalogTable
                    key={catalogTable.guid}
                    {...catalogTable}
                    dataOrders={dataOrders}
                    scrollPosition={scrollPosition}
                    onClick={catalogTable.number ? (() => onOpenCatalogTableSettings(catalogTable.guid)) : null} />
                </LazyLoadComponent>)}
          </div>
        </>
      }

      {storiesTables.length > 1 &&
        <>
          <div id={SCROLL_CATALOG_ID}>
            {data.filter(checkCatalogTable).length !== 0 &&
              <div style={{ textAlign: "center" }}>{__("text0016")?.toUpperCase()}</div>}
            {
              data.filter(checkCatalogTable).map((catalogTable, index) =>
                <LazyLoadComponent key={catalogTable.guid} index={index} scrollPosition={scrollPosition} threshold={10}>
                  <CatalogTable
                    key={catalogTable.guid}
                    index={index}
                    {...catalogTable}
                    dataOrders={dataOrders}
                    scrollPosition={scrollPosition}
                    onClick={catalogTable.number ? (() => onOpenCatalogTableSettings(catalogTable.guid)) : null} />
                </LazyLoadComponent>)}
          </div>
        </>
      }

      {data.filter(checkCatalogTable).length !== 0 &&
        <>
          <div style={{ padding: 12 }}>
            {type === 'new' && <OrdersAll dataOrders={dataOrders} type={type} />}
            {type === 'active' && <OrdersAll dataOrders={dataOrders} type={type} />}
          </div>
          <Button onClick={() => setType('new')} className={`catalog-table-new${type === "new" ? "-selected" : ""}`}>
            <div style={{ fontSize: 28, marginBottom: 5, marginTop: 5 }}>{statusСalculator.newLength}</div>
            <div>new</div>
          </Button>
          <Button onClick={() => setType('active')} className={`catalog-table-active${type === "active" ? "-selected" : ""}`}>
            <div style={{ fontSize: 28, marginBottom: 5, marginTop: 5 }}>{statusСalculator.activeLength}</div>
            <div>active</div>
          </Button>
        </>
      }

      <div className="empty-catalogItems-box"></div>
      <Slider
        opened={settingsOpened}
        onClose={closeCatalogTablesSettings}
        onSubmit={closeCatalogTablesSettings}
        submitTitle={__("text0007")?.toUpperCase()}
      >
        <CatalogTableSettings {...catalogTableData} dataOrders={dataOrders} />
      </Slider>


    </React.Fragment>
  );
};

CatalogTables.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

CatalogTables.defaultProps = {
  data: []
};

export default trackWindowScroll(CatalogTables);
