import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { trackWindowScroll, LazyLoadComponent } from 'react-lazy-load-image-component';
import { useCookies } from 'react-cookie';
import { getSearchParams } from 'utils/url';
import CatalogItemSettings from './CatalogItemSettings';
import Slider from 'components/common/Slider';
import { __ } from 'utils/translation';
import Button from 'components/common/Button';

import { SCROLL_CATALOG_ID } from 'constants/scrolls';

import Media from 'react-media';
import CatalogItemTablet from 'components/CatalogItems/CatalogItemTablet';
import CatalogItem from 'components/CatalogItems/CatalogItem';
import { USE_CATEGORIAS} from 'constants/actions';
import './index.scss';

const CatalogItems = ({ data, profile, scrollPosition }) => {
  const [settingsOpened, setSettingsOpened] = useState(null);
  const [catalogItemData, setCatalogItemData] = useState({});
  const [cookies] = useCookies();
  const { catalogItems } = useSelector((state) => state.config.data);
  const { stories } = useSelector((state) => state.config.data);
  const { storyGuidSelected, headerGuid, dataFiltered, dontUseIsBook } = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const closeCatalogItemsSettings = () => { 
    setSettingsOpened(null);
  };

  const onOpenCatalogItemSettings = (catalogItemId) => {
    setSettingsOpened(catalogItemId);
  };

  var inviteId = cookies[profile];
  if (inviteId === "undefined") {
    inviteId = null;
  };

  var params = getSearchParams(window.location.search);
  
  let paramsSortCategorias = "";

  let isOldVersion = false;
  if (params['waiter'] === "" || params['waiter']) {
    isOldVersion = true;
  };
  if (params['delivery'] === "" || params['delivery']) {
    isOldVersion = true;
  };
  if (params['takeAway'] === "" || params['takeAway']) {
    isOldVersion = true;
  };

  let isBookNoFoto = false;
  let isBookFoto = false;

  if (params['categoriacoctails'] === "" || params['categoriacoctails']) {
    paramsSortCategorias = "categoria-coctails";
    isBookNoFoto = true;
  };
  if (params['categoriabeer'] === "" || params['categoriabeer']) {
    paramsSortCategorias = "categoria-beer";
    isBookNoFoto = true;
  };
  if (params['categoriawine'] === "" || params['categoriawine']) {
    paramsSortCategorias = "categoria-wine";
    isBookNoFoto = true;
  };
  if (params['categoriawhicky'] === "" || params['categoriawhicky']) {
    paramsSortCategorias = "categoria-whicky";
    isBookNoFoto = true;
  };
  if (params['categoriabrandy'] === "" || params['categoriabrandy']) {
    paramsSortCategorias = "categoria-brandy";
    isBookNoFoto = true;
  };
  if (params['categoriacoffee'] === "" || params['categoriacoffee']) {
    paramsSortCategorias = "categoria-coffee";
    isBookNoFoto = true;
  };
  if (params['categoriavodka'] === "" || params['categoriavodka']) {
    paramsSortCategorias = "categoria-vodka";
    isBookNoFoto = true;
  };
  if (params['categoriagin'] === "" || params['categoriagin']) {
    paramsSortCategorias = "categoria-gin";
    isBookNoFoto = true;
  };
  if (params['categoriarum'] === "" || params['categoriarum']) {
    paramsSortCategorias = "categoria-rum";
    isBookNoFoto = true;
  };
  if (params['categoriaother'] === "" || params['categoriaother']) {
    paramsSortCategorias = "categoria-other";
    isBookNoFoto = true;
  };
  if (params['categoriasoftdrinks'] === "" || params['categoriasoftdrinks']) {
    paramsSortCategorias = "categoria-soft-drinks";
    isBookNoFoto = true;
  };
  if (params['categoriahookah'] === "" || params['categoriahookah']) {
    paramsSortCategorias = "categoria-hookah";
    isBookNoFoto = true;
  };
  if (params['categoriatequila'] === "" || params['categoriatequila']) {
    paramsSortCategorias = "categoria-tequila";
    isBookNoFoto = true;
  };
  if (params['categoriaaperitifs'] === "" || params['categoriaperitifs']) {
    paramsSortCategorias = "categoria-aperitifs";
    isBookNoFoto = true;
  };
  if (params['categoriadegistives'] === "" || params['categoriadegistives']) {
    paramsSortCategorias = "categoria-degistives";
    isBookNoFoto = true;
  };
  if (params['categoriasnacks'] === "" || params['categoriasnacks']) {
    paramsSortCategorias = "categoria-snacks";
    isBookNoFoto = true;
  };
  
  if (params['categorialiquore'] === "" || params['categorialiquore']) {
    paramsSortCategorias = "categoria-liquore";
    isBookNoFoto = true;
  };

  const dontusebooks = () => {
    dispatch({ type: USE_CATEGORIAS, dontUseIsBook: true});
  }

  useEffect(() => {
    const currentCatalogItem = data.find((catalogItem) => catalogItem.guid === settingsOpened);
    const settings = currentCatalogItem ;    
    setCatalogItemData({ ...settings });
  }, [settingsOpened, data]);

  const hour = new Date().getHours();
  const checkCatalogItem = (e) => {
    return (storyGuidSelected || (stories?.length ?? 0) === 0)
      && (!storyGuidSelected || !e.storyGuid || e.storyGuid === storyGuidSelected)
      && !e.outOfStock
      && ((!e.timeFrom && !e.timeTo) ||
        (e.timeFrom && e.timeTo && parseInt(e.timeFrom) <= hour && parseInt(e.timeTo) >= hour) ||
        (e.timeFrom && e.timeTo && parseInt(e.timeFrom) > parseInt(e.timeTo) && (parseInt(e.timeFrom) <= hour || parseInt(e.timeTo) <= hour)) ||
        (e.timeFrom && !e.timeTo && parseInt(e.timeFrom) <= hour) ||
        (!e.timeFrom && e.timeTo && parseInt(e.timeTo) >= hour))
      && (!e.headerGuid || dataFiltered || e.headerGuid === headerGuid);
  }

  // if (!inviteId && !active) { 
  //   return null;  
  // }

  data.sort((a, b) => a.order - b.order);
  return (
    <React.Fragment>
      
                {!isBookNoFoto && !isOldVersion &&
                <div className='catalogItemBook '>  
                    {
                      data.filter(checkCatalogItem).map((catalogItem, index) =>
                        <LazyLoadComponent key={catalogItem.guid} index={index} scrollPosition={scrollPosition} threshold={10}>
                            <CatalogItemTablet
                              key={catalogItem.guid}
                              index={index}
                              isBookNoFoto={true}
                              {...catalogItem}
                              scrollPosition={scrollPosition}
                              onClick={catalogItem.price || catalogItem.number ? (() => onOpenCatalogItemSettings(catalogItem.guid)) : null} />
                        </LazyLoadComponent>
                        )
                    }
                    <div className='catalogItemBottonBox'>
                      {!dontUseIsBook && (isBookFoto || isBookNoFoto) && <Button onClick={dontusebooks} >{__("Посмотреть все категории")}</Button>}
                    </div>
                </div>
                }

                {isBookNoFoto && !isOldVersion &&
                  <div className='catalogItemBook '>  
                      {
                        data.filter(checkCatalogItem).map((catalogItem, index) =>
                          <LazyLoadComponent key={catalogItem.guid} index={index} scrollPosition={scrollPosition} threshold={10}>
                              <CatalogItemTablet
                                key={catalogItem.guid}
                                index={index}
                                isBookNoFoto={true}
                                {...catalogItem}
                                scrollPosition={scrollPosition}
                                onClick={catalogItem.price || catalogItem.number ? (() => onOpenCatalogItemSettings(catalogItem.guid)) : null} />
                          </LazyLoadComponent>
                          )
                      }
                      <div className='catalogItemBottonBox'>
                      {!dontUseIsBook && (isBookFoto || isBookNoFoto) &&  <Button onClick={dontusebooks} >{__("Посмотреть все категории")}</Button>}
                      </div>
                  </div>
                }
                
                
                

    {!isBookFoto &&  !isBookNoFoto && isOldVersion &&
       <Media queries={{ small: { maxWidth: 599 } }}>
          {matches =>
            matches.small ? (
              <>
                {catalogItems.length > 0 &&
                  <div id={SCROLL_CATALOG_ID}>{
                    data.filter(checkCatalogItem).map((catalogItem, index) =>
                      <LazyLoadComponent key={catalogItem.guid} index={index} scrollPosition={scrollPosition} threshold={10}>
                        <CatalogItem
                          key={catalogItem.guid}
                          index={index}
                          {...catalogItem}
                          scrollPosition={scrollPosition}
                          onClick={catalogItem.price || catalogItem.number ? (() => onOpenCatalogItemSettings(catalogItem.guid)) : null} />
                        </LazyLoadComponent>)
                    }
                  </div>
                }
              </>
          ) : (
            <>
              <div className='catalogItem-tablet'>
                {catalogItems.length > 0 && 
                <>  
                    {
                      data.filter(checkCatalogItem).map((catalogItem, index) =>
                        // <LazyLoadComponent key={catalogItem.guid} index={index} scrollPosition={scrollPosition} threshold={10}>
                            <CatalogItemTablet
                              key={catalogItem.guid}
                              index={index}
                              isBookNoFoto={false}
                              {...catalogItem}
                              scrollPosition={scrollPosition}
                              onClick={catalogItem.price || catalogItem.number ? (() => onOpenCatalogItemSettings(catalogItem.guid)) : null} />
                        // </LazyLoadComponent>
                        )
                    }
                </>
                }
              </div>
            </>
          )
        }
      </Media>
    }

                
      <div className="empty-catalogItems-box"></div>
      <Slider
        opened={settingsOpened}
        onClose={closeCatalogItemsSettings}
        onSubmit={closeCatalogItemsSettings}
        submitTitle={__("text0007")?.toUpperCase()}
      >
        <CatalogItemSettings {...catalogItemData} />
      </Slider>
    </React.Fragment>
  );
};

CatalogItems.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

CatalogItems.defaultProps = {
  data: []
};

export default trackWindowScroll(CatalogItems);
