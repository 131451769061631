import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import OrderTables from '../OrderTables';
import { __, translatedProperty } from 'utils/translation';
import './index.scss';
import Review from 'components/Review';
// import ShowReview from 'components/Review/ShowReview';

const CatalogTableSettings = (props) => {
    const [type, setType] = useState('0');
    const [data, setData] = useState([]);
    const { settingsName } = useSelector((state) => state.config.data);
    const {
        guid,
        image,
        price,
        currency,
        number,
        reviews,
        dataOrders,
    } = props;

    const text = translatedProperty(props, "text");
    const textAlt = translatedProperty(props, "textAlt");
    const description = translatedProperty(props, "description");

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                hours: 48,
                tableNumber: Number(number),
                status: type
            })
        };
        fetch(`https://api.maps.menu/api/orders/params/${settingsName}/`, requestOptions)
            .then(response => response.json())
            .then(json => setData(json))
        return () => {
            console.log('clean type')
        }
    }, [type]);

    const statusСalculator = {
        newLength: (dataOrders.filter(order => order.status === "new"
            && order.tableNumber === Number(number)
        )).length,
        activeLength: (dataOrders.filter(order => order.status === "active"
            && order.tableNumber === Number(number)
        )).length,
        completedLength: (dataOrders.filter(order => order.status === "completed"
            && order.tableNumber === Number(number)
        )).length,
        cancelledLength: (dataOrders.filter(order => order.status === "cancelled"
            && order.tableNumber === Number(number)
        )).length
    };

    // const reviewImage = (reviews?.filter(r => r.isActive) ?? []).reverse().filter(r => r.image)[0]?.image;
    const imageCard = image
    // ?? reviewImage
    data.sort((a, b) => b.id - a.id);
    return (
        <React.Fragment>
            <div className="catalogItem-slider">
                {type !== "0" && (
                    <div className="catalogItem-slider__title">{__("text0019")}:&nbsp;{type}</div>
                )}
                {number && (
                    <div className="catalogItem-slider__number">{__("text0018")}: {number}</div>
                )}

                <div className="catalogTablesSettings-orders">
                    {
                        data.map((OrderTable) => <OrderTables key={OrderTable._id} {...OrderTable} />)
                    }
                </div>
                <Button style={{ width: "100%" }} onClick={() => setType('new')}><b style={{ fontSize: 18 }}>{statusСalculator.newLength}</b>&nbsp;{__("text0038")}: {number}</Button><br />
                <Button style={{ width: "100%" }} onClick={() => setType('active')}><b style={{ fontSize: 18 }}>{statusСalculator.activeLength}</b>&nbsp;{__("text0039")}: {number}</Button><br />
                <Button style={{ width: "100%" }} onClick={() => setType('completed')}><b style={{ fontSize: 18 }}>{statusСalculator.completedLength}</b>&nbsp;{__("text0040")}: {number}</Button><br />
                <Button style={{ width: "100%" }} onClick={() => setType('cancelled')}><b style={{ fontSize: 18 }}>{statusСalculator.cancelledLength}</b>&nbsp;{__("text0041")}: {number}</Button><br />

                <br />

                {/* <Review items={[{ ...props }]} /> */}

                <br />
                {/* <ShowReview data={props.reviews?.filter(r => r.isActive)} /> */}
            </div>
        </React.Fragment >
    );
};

CatalogTableSettings.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    image: PropTypes.string,
    text: PropTypes.string,
    description: PropTypes.string,
    textEn: PropTypes.string,
    descriptionEn: PropTypes.string,
    price: PropTypes.string,
    currency: PropTypes.string,
    number: PropTypes.string
};

CatalogTableSettings.defaultProps = {
    data: [],
    image: '',
    text: '',
    description: '',
    textEn: '',
    descriptionEn: '',
    price: '',
    currency: '',
    number: ''
};

export default CatalogTableSettings;

