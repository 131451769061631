import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector  } from 'react-redux';
import { __ } from 'utils/translation';
import Button from 'components/common/Button';
import Slider from 'components/common/Slider';
import langRu from 'images/russia.jpg';
import langDe from 'images/germany.jpg';
import langEn from 'images/usa.jpg';
import langFr from 'images/france.jpg';
import langIt from 'images/italy.jpg';
import langEs from 'images/spain.jpg';
import langZh from 'images/china.jpg';
import langPt from 'images/pt.jpg';
import langca from 'images/ca.jpg';
import langhu from 'images/hu.jpg';
import langel from 'images/el.jpg';
import langda from 'images/da.jpg';
import langlv from 'images/lv.jpg';
import langlt from 'images/lt.jpg';
import langet from 'images/et.jpg';
import langnl from 'images/nl.jpg';
import langpl from 'images/pl.jpg';
import langro from 'images/ro.jpg';
import langsk from 'images/sk.jpg';
import langsl from 'images/sl.jpg';
import langfi from 'images/fi.jpg';
import langcs from 'images/cs.jpg';
import langsv from 'images/sv.jpg';
import langja from 'images/ja.jpg';
import langaz from 'images/az.jpg';
import langbn from 'images/bn.jpg';
import langhi from 'images/hi.jpg';
import langga from 'images/ga.jpg';
import langhr from 'images/hr.jpg';
import langhy from 'images/hy.jpg';
import langis from 'images/is.jpg';
import langka from 'images/ka.jpg';
import langkk from 'images/kk.jpg';
import langmn from 'images/mn.jpg';
import langno from 'images/no.jpg';
import langsr from 'images/sr.jpg';
import langsq from 'images/sq.jpg';
import langtg from 'images/tg.jpg';
import langtk from 'images/tk.jpg';
import langtr from 'images/tr.jpg';
import languk from 'images/uk.jpg';
import languz from 'images/uz.jpg';
import langvi from 'images/vi.jpg';
import langbg from 'images/bg.jpg';

import { LANG_SETTINGS } from 'constants/actions';

import './index.scss';



const Lang = () => {
    const dispatch = useDispatch();
    const [changeLang, setChangeLang] = useState(false);
    const [choiceLanguage, setChoiceLanguage] = useState('');
    const [settingsOpened, setSettingsOpened] = useState(false);
    const language = (window.navigator.userLanguage || window.navigator.language)?.substring(0, 2)?.toLowerCase();

    const { langSelected } = useSelector((state) => state.config);
    
    useEffect(() => {
        dispatch({ type: LANG_SETTINGS, langApp: language });
    }, []);

    const changeLanguage = () => {
        setChangeLang (true);
        setSettingsOpened (true);
    };

    const closeSettings = () => {
        setSettingsOpened (false);
    };

    const choice = (lang) => {
        dispatch({ type: LANG_SETTINGS, langApp: lang });
        setChoiceLanguage(lang);
    }

    return (
        <React.Fragment>
            {!changeLang ? 
                <>
                    {language === 'ru' && <Button name="change language to russan" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langRu})` }} />}
                    {language === 'es' && <Button name="change language to espanol" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langEs})` }} />}
                    {language === 'fr' && <Button name="change language to france" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langFr})` }} />}
                    {language === 'it' && <Button name="change language to italian" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langIt})` }} />}
                    {language === 'de' && <Button name="change language to aleman" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langDe})` }} />}
                    {language === 'zh' && <Button name="change language to china" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langZh})` }} />}
                    {language === 'en' && <Button name="change language to english" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langEn})` }} />}
                    {language === 'pt' && <Button name="change language to pt" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langPt})` }} />}
                    {language === 'ca' && <Button name="change language to ca" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langca})` }} />}
                    {language === 'hu' && <Button name="change language to hu" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langhu})` }} />}
                    {language === 'el' && <Button name="change language to el" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langel})` }} />}
                    {language === 'da' && <Button name="change language to da" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langda})` }} />}
                    {language === 'lv' && <Button name="change language to lv" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langlv})` }} />}
                    {language === 'lt' && <Button name="change language to lt" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langlt})` }} />}
                    {language === 'et' && <Button name="change language to et" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langet})` }} />}
                    {language === 'nl' && <Button name="change language to nl" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langnl})` }} />}
                    {language === 'pl' && <Button name="change language to pl" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langpl})` }} />}
                    {language === 'bg' && <Button name="change language to bg" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langbg})` }} />}
                    {language === 'ro' && <Button name="change language to ro" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langro})` }} />}
                    {language === 'sk' && <Button name="change language to sk" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langsk})` }} />}
                    {language === 'sl' && <Button name="change language to sl" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langsl})` }} />}
                    {language === 'fi' && <Button name="change language to fi" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langfi})` }} />}
                    {language === 'cs' && <Button name="change language to cs" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langcs})` }} />}
                    {language === 'sv' && <Button name="change language to sv" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langsv})` }} />}
                    {language === 'ja' && <Button name="change language to ja" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langja})` }} />}
                    {language === 'az' && <Button name="change language to az" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langaz})` }} />}
                    {language === 'bn' && <Button name="change language to bn" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langbn})` }} />}
                    {language === 'hi' && <Button name="change language to hi" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langhi})` }} />}
                    {language === 'ga' && <Button name="change language to ga" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langga})` }} />}
                    {language === 'hr' && <Button name="change language to hr" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langhr})` }} />}
                    {language === 'hy' && <Button name="change language to hy" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langhy})` }} />}
                    {language === 'is' && <Button name="change language to is" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langis})` }} />}
                    {language === 'ka' && <Button name="change language to ka" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langka})` }} />}
                    {language === 'kk' && <Button name="change language to kk" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langkk})` }} />}
                    {language === 'mn' && <Button name="change language to mn" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langmn})` }} />}
                    {language === 'no' && <Button name="change language to no" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langno})` }} />}
                    {language === 'sr' && <Button name="change language to sr" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langsr})` }} />}
                    {language === 'sq' && <Button name="change language to sq" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langsq})` }} />}
                    {language === 'tg' && <Button name="change language to tg" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langtg})` }} />}
                    {language === 'tk' && <Button name="change language to tk" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langtk})` }} />}
                    {language === 'tr' && <Button name="change language to tr" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langtr})` }} />}
                    {language === 'uk' && <Button name="change language to uk" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${languk})` }} />}
                    {language === 'uz' && <Button name="change language to uz" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${languz})` }} />}
                    {language === 'vi' && <Button name="change language to vi" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langvi})` }} />}
                    
                </> 
                :
                <>
                    {choiceLanguage === 'ru' && <Button name="change language to russan" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langRu})` }} />}
                    {choiceLanguage === 'es' && <Button name="change language to espanol" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langEs})` }} />}
                    {choiceLanguage === 'fr' && <Button name="change language to france" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langFr})` }} />}
                    {choiceLanguage === 'it' && <Button name="change language to italian" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langIt})` }} />}
                    {choiceLanguage === 'de' && <Button name="change language to aleman" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langDe})` }} />}
                    {choiceLanguage === 'zh' && <Button name="change language to china" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langZh})` }} />}
                    {choiceLanguage === 'en' && <Button name="change language to english" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langEn})` }} />} 
                    {choiceLanguage === 'pt' && <Button name="change language to Pt" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langPt})` }} />}
                    {choiceLanguage === 'ca' && <Button name="change language to ca" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langca})` }} />}
                    {choiceLanguage === 'hu' && <Button name="change language to hu" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langhu})` }} />}
                    {choiceLanguage === 'el' && <Button name="change language to el" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langel})` }} />}
                    {choiceLanguage === 'da' && <Button name="change language to da" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langda})` }} />} 
                    {choiceLanguage === 'lv' && <Button name="change language to lv" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langlv})` }} />}
                    {choiceLanguage === 'lt' && <Button name="change language to lt" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langlt})` }} />}
                    {choiceLanguage === 'et' && <Button name="change language to et" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langet})` }} />}
                    {choiceLanguage === 'nl' && <Button name="change language to nl" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langnl})` }} />}
                    {choiceLanguage === 'pl' && <Button name="change language to pl" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langpl})` }} />}
                    {choiceLanguage === 'bg' && <Button name="change language to bg" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langbg})` }} />}
                    {choiceLanguage === 'ro' && <Button name="change language to ro" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langro})` }} />} 
                    {choiceLanguage === 'sk' && <Button name="change language to sk" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langsk})` }} />}
                    {choiceLanguage === 'sl' && <Button name="change language to sl" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langsl})` }} />}
                    {choiceLanguage === 'fi' && <Button name="change language to fi" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langfi})` }} />}
                    {choiceLanguage === 'cs' && <Button name="change language to cs" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langcs})` }} />}
                    {choiceLanguage === 'sv' && <Button name="change language to sv" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langsv})` }} />}
                    {choiceLanguage === 'ja' && <Button name="change language to ja" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langja})` }} />}
                    {choiceLanguage === 'az' && <Button name="change language to az" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langaz})` }} />}
                    {choiceLanguage === 'bn' && <Button name="change language to bn" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langbn})` }} />}
                    {choiceLanguage === 'hi' && <Button name="change language to hi" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langhi})` }} />} 
                    {choiceLanguage === 'ga' && <Button name="change language to ga" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langga})` }} />}
                    {choiceLanguage === 'hr' && <Button name="change language to hr" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langhr})` }} />}
                    {choiceLanguage === 'hy' && <Button name="change language to hy" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langhy})` }} />}
                    {choiceLanguage === 'is' && <Button name="change language to is" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langis})` }} />}
                    {choiceLanguage === 'ka' && <Button name="change language to ka" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langka})` }} />} 
                    {choiceLanguage === 'kk' && <Button name="change language to kk" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langkk})` }} />} 
                    {choiceLanguage === 'mn' && <Button name="change language to mn" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langmn})` }} />}
                    {choiceLanguage === 'no' && <Button name="change language to no" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langno})` }} />} 
                    {choiceLanguage === 'sr' && <Button name="change language to sr" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langsr})` }} />}
                    {choiceLanguage === 'sq' && <Button name="change language to sq" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langsq})` }} />}
                    {choiceLanguage === 'tg' && <Button name="change language to tg" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langtg})` }} />}
                    {choiceLanguage === 'tk' && <Button name="change language to tk" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langtk})` }} />}
                    {choiceLanguage === 'tr' && <Button name="change language to tr" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langtr})` }} />}
                    {choiceLanguage === 'uk' && <Button name="change language to uk" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${languk})` }} />}
                    {choiceLanguage === 'uz' && <Button name="change language to uz" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${languz})` }} />}
                    {choiceLanguage === 'vi' && <Button name="change language to vi" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langvi})` }} />}                    
                </> 
            }
            <Slider
                opened={settingsOpened}
                onClose={choiceLanguage !=='' ? closeSettings : null}
                onSubmit={choiceLanguage !=='' ? closeSettings : null}
                submitTitle={__("text0007")?.toUpperCase()}
                title={__("text0043")}
                subtitle={choiceLanguage !=='' ? (__("text0044") +  " " + __("text0045")) : null}

            >
                <div className='lang-scroll-box'>
                    <Button name="change language to english" noStyle onClick={()=> choice('en')} className={langSelected === 'en' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langEn})` }} />
                    <Button name="change language to russan" noStyle onClick={()=> choice('ru')} className={langSelected === 'ru' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langRu})` }} />
                    <Button name="change language to espanol" noStyle onClick={()=> choice('es')} className={langSelected === 'es' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langEs})` }} />
                    <Button name="change language to ca" noStyle onClick={()=> choice('ca')} className={langSelected === 'ca' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langca})` }} />
                    <Button name="change language to france" noStyle onClick={()=> choice('fr')} className={langSelected === 'fr' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langFr})` }} />
                    <Button name="change language to italian" noStyle onClick={()=> choice('it')} className={langSelected === 'it' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langIt})` }} />
                    <Button name="change language to aleman" noStyle onClick={()=> choice('de')} className={langSelected === 'de' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langDe})` }} />
                    <Button name="change language to pt" noStyle onClick={()=> choice('pt')} className={langSelected === 'pt' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langPt})` }} />
                    <Button name="change language to da" noStyle onClick={()=> choice('da')} className={langSelected === 'da' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langda})` }} />
                    <Button name="change language to nl" noStyle onClick={()=> choice('nl')} className={langSelected === 'nl' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langnl})` }} />
                    <Button name="change language to no" noStyle onClick={()=> choice('no')} className={langSelected === 'no' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langno})` }} />
                    <Button name="change language to fi" noStyle onClick={()=> choice('fi')} className={langSelected === 'fi' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langfi})` }} />
                    <Button name="change language to sv" noStyle onClick={()=> choice('sv')} className={langSelected === 'sv' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langsv})` }} />
                    <Button name="change language to ga" noStyle onClick={()=> choice('ga')} className={langSelected === 'ga' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langga})` }} />
                    <Button name="change language to is" noStyle onClick={()=> choice('is')} className={langSelected === 'is' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langis})` }} />

                    <Button name="change language to uk" noStyle onClick={()=> choice('uk')} className={langSelected === 'uk' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${languk})` }} />
                    <Button name="change language to lv" noStyle onClick={()=> choice('lv')} className={langSelected === 'lv' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langlv})` }} />
                    <Button name="change language to lt" noStyle onClick={()=> choice('lt')} className={langSelected === 'lt' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langlt})` }} />
                    <Button name="change language to et" noStyle onClick={()=> choice('et')} className={langSelected === 'et' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langet})` }} />
                    <Button name="change language to pl" noStyle onClick={()=> choice('pl')} className={langSelected === 'pl' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langpl})` }} />
                    <Button name="change language to bg" noStyle onClick={()=> choice('bg')} className={langSelected === 'bg' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langbg})` }} />
                    <Button name="change language to cs" noStyle onClick={()=> choice('cs')} className={langSelected === 'cs' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langcs})` }} />
                    <Button name="change language to sk" noStyle onClick={()=> choice('sk')} className={langSelected === 'sk' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langsk})` }} />
                    <Button name="change language to sl" noStyle onClick={()=> choice('sl')} className={langSelected === 'sl' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langsl})` }} /> 
                    <Button name="change language to ro" noStyle onClick={()=> choice('ro')} className={langSelected === 'ro' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langro})` }} />
                    <Button name="change language to hr" noStyle onClick={()=> choice('hr')} className={langSelected === 'hr' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langhr})` }} />
                    <Button name="change language to hu" noStyle onClick={()=> choice('hu')} className={langSelected === 'hu' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langhu})` }} />
                    <Button name="change language to sr" noStyle onClick={()=> choice('sr')} className={langSelected === 'sr' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langsr})` }} />
                    <Button name="change language to sq" noStyle onClick={()=> choice('sq')} className={langSelected === 'sq' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langsq})` }} />
                    <Button name="change language to el" noStyle onClick={()=> choice('el')} className={langSelected === 'el' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langel})` }} />
                    <Button name="change language to tr" noStyle onClick={()=> choice('tr')} className={langSelected === 'tr' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langtr})` }} />

                    <Button name="change language to az" noStyle onClick={()=> choice('az')} className={langSelected === 'az' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langaz})` }} />
                    <Button name="change language to hy" noStyle onClick={()=> choice('hy')} className={langSelected === 'hy' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langhy})` }} />
                    <Button name="change language to ka" noStyle onClick={()=> choice('ka')} className={langSelected === 'ka' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langka})` }} />
                    <Button name="change language to kk" noStyle onClick={()=> choice('kk')} className={langSelected === 'kk' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langkk})` }} />
                    <Button name="change language to tg" noStyle onClick={()=> choice('tg')} className={langSelected === 'tg' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langtg})` }} />
                    <Button name="change language to tk" noStyle onClick={()=> choice('tk')} className={langSelected === 'tk' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langtk})` }} />
                    <Button name="change language to uz" noStyle onClick={()=> choice('uz')} className={langSelected === 'uz' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${languz})` }} />

                    <Button name="change language to mn" noStyle onClick={()=> choice('mn')} className={langSelected === 'mn' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langmn})` }} />
                    <Button name="change language to vi" noStyle onClick={()=> choice('vi')} className={langSelected === 'vi' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langvi})` }} />
                    <Button name="change language to bn" noStyle onClick={()=> choice('bn')} className={langSelected === 'bn' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langbn})` }} />
                    <Button name="change language to hi" noStyle onClick={()=> choice('hi')} className={langSelected === 'hi' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langhi})` }} />
                    <Button name="change language to china" noStyle onClick={()=> choice('zh')} className={langSelected === 'zh' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langZh})` }} />
                    <Button name="change language to ja" noStyle onClick={()=> choice('ja')} className={langSelected === 'ja' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langja})` }} />
                    
               </div>
                
            </Slider>
        </React.Fragment >
    );
};

export default Lang;