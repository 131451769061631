import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { useCookies } from 'react-cookie';
import Story from 'components/Stories/Story';
import StoryBook from 'components/Stories/StoryBook';
import SearchComponent from 'components/SearchComponent';
import { getSearchParams } from 'utils/url';
import { __ } from 'utils/translation';
import addedMenu from 'images/desserts.jpg';
import { CATALOG_FILTER, CATALOG_SEARCH } from 'constants/actions';

import './index.scss';
import 'components/Stories/index.scss';

const Stories = ({ data, profile, scrollPosition }) => {
  const [cookies] = useCookies();


  const { stories = [] } = useSelector((state) => state.config.data);
  const { catalogItems = [] } = useSelector((state) => state.config.data);
  const { storyGuid, storyGuidSelected, search, dontUseIsBook } = useSelector((state) => state.config);
  const dispatch = useDispatch();

  var params = getSearchParams(window.location.search);

  let paramsSortCategorias = "";
  let isOldVersion = false;
  if (params['waiter'] === "" || params['waiter']) {
    isOldVersion = true;
  };
  if (params['delivery'] === "" || params['delivery']) {
    isOldVersion = true;
  };
  if (params['takeAway'] === "" || params['takeAway']) {
    isOldVersion = true;
  };

  let isBook = false;

  if (params['categoriacoctails'] === "" || params['categoriacoctails']) {
    paramsSortCategorias = "categoria-coctails";
    isBook = true;
  };
  if (params['categoriabeer'] === "" || params['categoriabeer']) {
    paramsSortCategorias = "categoria-beer";
    isBook = true;
  };
  if (params['categoriawine'] === "" || params['categoriawine']) {
    paramsSortCategorias = "categoria-wine";
    isBook = true;
  };
  if (params['categoriawhicky'] === "" || params['categoriawhicky']) {
    paramsSortCategorias = "categoria-whicky";
    isBook = true;
  };
  if (params['categoriabrandy'] === "" || params['categoriabrandy']) {
    paramsSortCategorias = "categoria-brandy";
    isBook = true;
  };
  if (params['categoriacoffee'] === "" || params['categoriacoffee']) {
    paramsSortCategorias = "categoria-coffee";
    isBook = true;
  };
  if (params['categoriavodka'] === "" || params['categoriavodka']) {
    paramsSortCategorias = "categoria-vodka";
    isBook = true;
  };
  if (params['categoriagin'] === "" || params['categoriagin']) {
    paramsSortCategorias = "categoria-gin";
    isBook = true;
  };
  if (params['categoriarum'] === "" || params['categoriarum']) {
    paramsSortCategorias = "categoria-rum";
    isBook = true;
  };
  if (params['categoriaother'] === "" || params['categoriaother']) {
    paramsSortCategorias = "categoria-other";
    isBook = true;
  };
  if (params['categoriasoftdrinks'] === "" || params['categoriasoftdrinks']) {
    paramsSortCategorias = "categoria-soft-drinks";
    isBook = true;
  };
  if (params['categoriahookah'] === "" || params['categoriahookah']) {
    paramsSortCategorias = "categoria-hookah";
    isBook = true;
  };
  if (params['categoriatequila'] === "" || params['categoriatequila']) {
    paramsSortCategorias = "categoria-tequila";
    isBook = true;
  };
  if (params['categoriaaperitifs'] === "" || params['categoriaperitifs']) {
    paramsSortCategorias = "categoria-aperitifs";
    isBook = true;
  };
  if (params['categoriadegistives'] === "" || params['categoriadegistives']) {
    paramsSortCategorias = "categoria-degistives";
    isBook = true;
  };
  if (params['categoriasnacks'] === "" || params['categoriasnacks']) {
    paramsSortCategorias = "categoria-snacks";
    isBook = true;
  };
  
      
  if (params['categorialiquore'] === "" || params['categorialiquore']) {
    paramsSortCategorias = "categoria-liquore";
    isBook = true;
  };

  
  useEffect(() => {
    if (!isBook || dontUseIsBook ) {
      if (stories.length > 0) { 
        const stories0 = stories[0];
        const storyId = stories0.guid
        if (catalogItems.filter(e => e.storyGuid === storyId).length > 0) {
          dispatch({ type: CATALOG_FILTER, storyGuid: storyGuid !== storyId ? storyId : null });
        };
      };
    };

    if (isBook) {
      const storiesOfCategory = stories.filter(item => item.linkUrl === paramsSortCategorias) 
      const stories0 = storiesOfCategory[0] || [] ;
      const storyId = stories0.guid
        if (catalogItems.filter(e => e.storyGuid === storyId).length > 0) {
          dispatch({ type: CATALOG_FILTER, storyGuid: storyGuid !== storyId ? storyId : null });
        }; 
      };
}, []);

const storiesOfCategory = stories.filter(item => item.linkUrl === paramsSortCategorias)


const handleStoryClick = (storyId) => {
  if (catalogItems.filter(e => e.storyGuid === storyId).length > 0) {
    dispatch({ type: CATALOG_FILTER, storyGuid: storyGuid !== storyId ? storyId : null });
  };
};

const handleSearch = (value) => {
  dispatch({ type: CATALOG_SEARCH, search: value });
};

var inviteId = cookies[profile];
if (inviteId === "undefined") {
  inviteId = null;
};

const placeholder = "Search";

stories.sort((a, b) => a.order - b.order);

return (
  <React.Fragment>

    {catalogItems.length > 0 && stories.length > 1 && !isBook && isOldVersion &&
      <div className="stories">
        <SearchComponent search={search} placeholder={placeholder} onSearch={handleSearch} />
        <div className="stories-picker">
          {data.map((story, index) =>
            <Story className={classnames([`stories-picker-item`])}
              onClick={() => handleStoryClick(story.guid)}
              index={index}
              key={story.guid} {...story}
              selected={storyGuidSelected === story.guid}
              scrollPosition={scrollPosition} />)
          }
        </div>
      </div>
    }

    {catalogItems.length > 0 && stories.length > 1 && (!isBook || dontUseIsBook) && !isOldVersion &&
      <div className="stories">
        <SearchComponent search={search} placeholder={placeholder} onSearch={handleSearch} />
        <div className="stories-picker">
          {data.map((story, index) =>
            <StoryBook className={classnames([`storiesBook-picker-item `])}
              onClick={() => handleStoryClick(story.guid)}
              index={index}
              key={story.guid} {...story}
              selected={storyGuidSelected === story.guid}
              scrollPosition={scrollPosition} />)
          }
        </div>
      </div>
    }   

    {catalogItems.length > 0 && storiesOfCategory.length > 1 && (isBook && !dontUseIsBook) && !isOldVersion &&
      <div className="stories">
        {/* <SearchComponent search={search} placeholder={placeholder} onSearch={handleSearch} /> */}
        <div className="stories-picker">
          {data.filter(item => (item.linkUrl === paramsSortCategorias))
          .map((story, index) =>
            <StoryBook className={classnames([`storiesBook-picker-item `])}
              onClick={() => handleStoryClick(story.guid)}
              index={index}
              key={story.guid} {...story}
              selected={storyGuidSelected === story.guid}
              scrollPosition={scrollPosition} />)
          }
        </div>
      </div>
    }

    

  </React.Fragment>
);
};

Stories.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

Stories.defaultProps = {
  data: []
};

export default trackWindowScroll(Stories);
