import translates from "constants/translation.json";

let lang = null;

export const supportedLanguages = [ "en", "ru", "es", "fr", "de", "it", "zh", "bg", "hu", "el", "da", "lv", "lt", "nl", "pl", "ro", "sl",
"sk", "fi", "cs", "sv", "et", "ja", "pt", 
"vi", "az", "bn", "ca", "ga", "hi", "hr", "hy", "is", "ka", "kk", "mn", "no", "sr", "sq", "tg", "tk", "tr", "uk", "uz" ];

export const __ = (text, language) => {
    text = text.toLowerCase();
    const defaultLang = getDefaultLanguage();

    const items = translates.filter(e => e.code.toLowerCase() === text || e.ru.toLowerCase() === text || e.en.toLowerCase() === text);
    if (items.length === 0) {
        return "{{" + text + ":" + ((language ?? lang) ?? defaultLang) + "}}";
    }

    return items[0][(language ?? lang) ?? defaultLang] ?? items[0].en;
};

export const translatedProperty = (props, propName, language) => {
    if (!props) {
        return "";
    }
    const defaultLang = (language ?? lang) ?? getDefaultLanguage();
    const localizedPropName = propName + defaultLang[0].toUpperCase() + defaultLang[1];
    let result = props[localizedPropName];
    if (result) {
        return result;
    }
    result = props[propName];
    if (result) {
        return result;
    }
    return "";
};

export const translatedPropertyName = (propName, language) => {
    const defaultLang = (language ?? lang) ?? getDefaultLanguage();
    return propName + defaultLang[0].toUpperCase() + defaultLang[1];
};

export const setLocalizationLang = (value) => {
    lang = value?.toLowerCase();
}

export const getLocalizationLang = () => {
    return lang;
}

export const getDefaultLanguage = () => {
    var language = (window.navigator.userLanguage || window.navigator.language)?.substring(0, 2)?.toLowerCase();

    if (supportedLanguages.indexOf(language) >= 0) {
        return language;
    }

    return supportedLanguages[0];
}
