import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import { select } from 'redux-saga/effects';
import menuOnline from 'images/empty.png';
import Button from 'components/common/Button';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { __ } from 'utils/translation';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './index.scss';
import 'components/Stories/Story/index.scss';

import { translatedProperty } from 'utils/translation';


const StoryBook = (props) => {

    const {
        image,
        onClick,
        className,
        selected,
        scrollPosition,
        index
    } = props;
    const title = translatedProperty(props, "title")

    const story = (
            <div className={classnames([className])} onClick={onClick} >
                {!selected? 
                    <div className="storyBook-picture" > <img
                        src={image}
                        alt={title}
                        height={100}
                        width={100}
                    />
                    </div> 
            : 
                    <div className="storyBookSelected-picture" > <img
                        src={image}
                        alt={title}
                        height={100}
                        width={100}
                    />
                    </div>
                } 
            </div>
        
    );
    return story;
}

StoryBook.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,

};

StoryBook.defaultProps = {
    title: '',
    image: undefined,

};

export default StoryBook;
